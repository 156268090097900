<script lang="ts">
	import '../styles/app.css';

	let { children } = $props();
	const isDev = import.meta.env.DEV;
</script>

<svelte:head>
	{#if !isDev}
		<!-- Google tag (gtag.js) -->
		<script async src="https://www.googletagmanager.com/gtag/js?id=G-4CVTPYC2NY"></script>
		<script>
			window.dataLayer = window.dataLayer || [];
			function gtag() {
				dataLayer.push(arguments);
			}
			gtag('js', new Date());

			gtag('config', 'G-4CVTPYC2NY');
		</script>

		<!-- Adopt -->
		<meta name="adopt-website-id" content="9038fbe3-cf97-41d6-8881-800ef5d131fb" />
		<script
			src="//tag.goadopt.io/injector.js?website_code=9038fbe3-cf97-41d6-8881-800ef5d131fb"
			class="adopt-injector"
		></script>
	{/if}
</svelte:head>

{@render children()}
